import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import NewLayout from "../components/NewLayout";
import Hero from "../components/Hero";
import Container from "../components/Container";

function DataProtectionPage() {
  return (
    <NewLayout apps="careers">
      <Hero
        kind="INFORMATIVA EX art. 13 Regolamento UE 2016/679"
        title="Regolamento Generale sulla Protezione dei Dati"
      />
      <Container py={8}>
        <Stack spacing={4}>
          <Box>
            <Typography variant="h3" mb={1}>1 - Titolare del trattamento</Typography>
            <Typography>
              Il Titolare del trattamento è ALLFUNDS BANK, S.A.U., Milan Branch,
              domiciliata in Via Bocchetto 6, 20123 Milano. Il contatto del
              Responsabile della protezione dei dati è: dpo@allfunds.com.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" mb={1}>
              2 - Finalità e liceità del trattamento
            </Typography>
            <Stack spacing={1}>
              <Typography>
                ALLFUNDS informa che i Vostri dati saranno trattati per il
                compimento delle seguenti finalità: Operazioni in Italia aventi
                ad oggetto quote / azioni di organismi di investimento
                collettivo del risparmio (compilazione del modulo di
                sottoscrizione, verifica della regolarità dei dati, emissione
                delle lettere di conferma delle operazioni, etc..) anche
                mediante uso di procedure informatiche e telematiche.
              </Typography>
              <Typography>
                Le finalità del trattamento sono dunque connesse con obblighi di
                legge o regolamento nonché con gli obblighi contrattuali
                derivanti dalla sottoscrizione di quote / azioni di organismi di
                investimento collettivo del risparmio.
              </Typography>
              <Typography>
                La comunicazione dei dati personali è obbligatoria per adempiere
                a obblighi normativi e per dare esecuzione alle pattuizioni di
                cui al modulo di sottoscrizione, nonché alla documentazione di
                offerta dell’organismo di investimento collettivo del risparmio.
                La mancata comunicazione comporta l’impossibilità di dare
                seguito alla richiesta di sottoscrizione e alle attività
                successive alla stessa. Comunicazione dei dati personali:
              </Typography>
              <Typography>
                I dati personali raccolti potranno essere messi a disposizione
                (anche successivamente al momento della raccolta) degli
                emittenti (SICAV), della Società di Gestione, della banca
                depositaria, dei Soggetti incaricati dei pagamenti, dei Soggetti
                Collocatori, tutti operanti in qualità di Titolari del
                trattamento tra loro autonomi, i quali, per taluni servizi,
                possono avvalersi di soggetti terzi di propria fiducia, (ad
                esempio società che svolgono servizi bancari, finanziari ed
                amministrativi o dei loro incaricati, che intervengono a vario
                titolo nelle operazioni di sottoscrizione, acquisto, vendita,
                trasferimenti) che, nell’ambito del trattamento di dati
                personali, assumono la qualifica di Responsabili del
                trattamento.
              </Typography>
              <Typography>
                Inoltre i dati potranno essere comunicati a soggetti che
                effettuano operazioni di spedizione e/o di imbustamento, società
                o soggetti incaricati della certificazione dei bilanci, a
                società esterne specializzate nella riproduzione digitalizzata e
                nell’archiviazione in qualunque forma ammissibile. I soggetti
                destinatari della comunicazione dei dati tratteranno gli stessi
                solo per finalità connesse al rapporto, con preclusione di
                comunicazione a terzi.
              </Typography>
              <Typography>
                In relazione al trattamento dei dati personali, si informano gli
                interessati che gli stessi potranno essere trasferiti verso
                Paesi terzi e organizzazioni internazionali, anche al di fuori
                dell’Unione Europea, nella misura ragionevolmente necessaria per
                consentire di prestare i servizi di sottoscrizione delle quote /
                azioni di organismi di investimento collettivo del risparmio in
                Italia, nonché ogni altra attività successiva all’operazione di
                sottoscrizione purché in ogni caso detto trasferimento sia
                eseguito in conformità con le normative applicabili ed in Paesi
                verso i quali esiste una decisione di adeguatezza promulgata
                dalla Commissione Europea.
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h3" mb={1}>
              3 - Per quanto tempo Allfunds procederà alla conservazione dei
              dati degli interessati
            </Typography>
            <Typography>
              ALLFUNDS effettuerà il trattamento dei dati dell’interessato fino
              a che il Collocatore non informerà ALLFUNDS della cessazione del
              rapporto contrattuale tra il Collocatore e il cliente finale.
              ALLFUNDS conserverà i dati per metterli a disposizione di enti
              pubblici, enti amministrativi, organi giurisdizionali , autorità
              giudiziaria, autorità di polizia, autorità di vigilanza o per la
              risoluzione delle controversie che siano potute insorgere e che
              derivino dal compimento delle obbligazioni delle Parti del
              presente contratto. Una volta scaduto il termine di prescrizione
              legale applicabile alla conservazione dei documenti, ALLFUNDS
              procederà alla cancellazione dei dati personali.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" mb={1}>
              4 - A chi potranno essere comunicati i dati dell’interessato
            </Typography>
            <Typography>
              I dati personali degli interessati potranno essere trasmessi alle
              Società di seguito riportate, sempre nel rispetto delle garanzie
              stabilite dalla normativa in materia di protezione dei dati
              personali:
            </Typography>
            <ol>
              <li>
                <Typography>Altre Entità del Gruppo Allfunds.</Typography>
              </li>
              <li>
                <Typography>
                  La Sicav, La Società di Gestione, la Banca Depositaria, i
                  Collocatori, il Soggetto Incaricato dei Pagamenti.
                </Typography>
              </li>
              <li>
                <Typography>
                  Enti pubblici, enti amministrativi, organi giurisdizionali,
                  autorità giudiziaria, autorità di polizia, autorità di
                  vigilanza.
                </Typography>
              </li>
              <li>
                <Typography>
                  Fornitori terzi di servizi che ALLFUNDS può contrattare e che
                  possono avere accesso ai dati personali dell’interessato.
                  Qualora la comunicazione possa essere fatta verso Entità
                  residenti in Paesi terzi, anche al di fuori dell’Unione
                  Europea, il trasferimento verrà effettuato in ottemperanza
                  alle disposizioni normative vigenti ed in Paesi verso i quali
                  esiste una decisione di adeguatezza promulgata dalla
                  Commissione Europea.
                </Typography>
              </li>
            </ol>
          </Box>
          <Box>
            <Typography variant="h3" mb={1}>5 - Diritti dell’interessato</Typography>
            <Stack spacingg={1}>
              <Typography>
                ALLFUNDS informa l’interessato che può esercitare i seguenti
                diritti:
              </Typography>
              <Typography>
                Diritto all’accesso: l’interessato ha il diritto di ottenere dal
                Titolare del trattamento la conferma che sia o meno in corso un
                trattamento di dati personali che lo riguardano e, in tal caso,
                di ottenere l’accesso alle finalità del trattamento, al tempo di
                conservazione, alle categorie di destinatari ai quali i dati
                potrebbero essere comunicati, etc..
              </Typography>
              <Typography>
                Diritto di rettifica: l’interessato ha il diritto di ottenere
                dal Titolare del trattamento la rettifica dei dati personali
                inesatti che lo riguardano senza ingiustificato ritardo.
              </Typography>
              <Typography>
                Diritto di cancellazione: l’interessato ha il diritto di
                chiedere al Titolare del trattamento la cancellazione dei dati
                personali quando non sono più necessari rispetto alle finalità
                per le quali sono stati raccolti.
              </Typography>
              <Typography>
                Diritto di opposizione: l’interessato ha il diritto di opporsi,
                in qualsiasi momento, al trattamento dei suoi dati per motivi
                connessi alla sua situazione particolare.
              </Typography>
              <Typography>
                Diritto di portabilità: l’interessato ha il diritto di
                trasmettere tali dati a un altro titolare del trattamento, senza
                impedimenti da parte del Titolare del trattamento, qualora il
                trattamento si basi sul consenso dell’interessato o in base al
                compimento di un obbligo contrattuale.
              </Typography>
              <Typography>
                Diritto di limitazione del trattamento: l’interessato ha il
                diritto di ottenere la limitazione del trattamento quando:
              </Typography>
              <ul>
                <li>
                  <Typography>
                    l’interessato contesta l’esattezza dei dati personali;
                  </Typography>
                </li>
                <li>
                  <Typography>
                    il trattamento è illecito e l’interessato si oppone alla
                    cancellazione dei dati personali e chiede invece che ne sia
                    limitato l’utilizzo;
                  </Typography>
                </li>
                <li>
                  <Typography>
                    l’interessato manifesta la sua opposizione al trattamento,
                    mentre il titolare ribadisce i motivi legittimi per il
                    trattamento dei dati.
                  </Typography>
                </li>
              </ul>
              <Typography>
                Diritto a non essere oggetto di un processo decisionale
                automatizzato: l’interessato ha il diritto a non essere
                sottoposto ad una decisione basata unicamente sul trattamento
                automatizzato qualora non sia necessaria per la conclusione o
                l’esecuzione del contratto, non sia autorizzata dal diritto
                dell’Unione o dal diritto italiano, e non si basi sul consenso
                esplicito dell’interessato. In ogni caso, l’interessato ha il
                diritto di ottenere l’intervento umano da parte del titolare del
                trattamento, di esprimere la propria opinione e di contestare la
                decisione.
              </Typography>
              <Typography>
                L’interessato potrà esercitare i suoi diritti sopra elencati
                tramite richiesta scritta inviata a: ALLFUNDS BANK, S.A.U.,
                Milan Branch, Via Bocchetto 6 – 20123 Milano o mediante posta
                elettronica a : dpo@allfunds.com.
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h3" mb={1}>
              6 - Possibili reclami alle Autorità di Vigilanza
            </Typography>
            <Typography>
              L’interessato, qualora lo ritenga opportuno, potrà presentare un
              reclamo alle Autorità di vigilanza competenti in materia di
              protezione dei dati personali, qualora non abbia ottenuto
              soddisfazione nell’esercizio dei suoi diritti.
            </Typography>
          </Box>
        </Stack>
      </Container>
    </NewLayout>
  );
}
export default DataProtectionPage;
